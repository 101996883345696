import { Component, Input, OnInit } from '@angular/core';

import { Sensor } from '../../model/sensor';
import { DateFormatSettings } from '../../model/settings';
import { AssetsService } from '../../services/assets.service';

@Component({
  selector: 'sensor-subscription',
  templateUrl: './sensor-subscription.html',
  styleUrls: ['./sensor-subscription.scss'],
})
export class SensorSubscriptionComponent implements OnInit {
  @Input() Sensor: Sensor = null;
  @Input() dateFormat: DateFormatSettings = null;
  sensorSubscriptionDetails = null;

  constructor(private assetsService: AssetsService) {}

  ngOnInit() {
    this.getSubscriptionDetails();
  }

  get DateFormat(): string {
    return !!this.dateFormat && this.dateFormat.formatString === 'MM/dd/yyyy'
      ? 'MM/dd/yyyy'
      : 'dd-MM-yyyy';
  }

  getSubscriptionDetails() {
    if (!!this.Sensor) {
      this.sensorSubscriptionDetails = this.assetsService
        .getSensorSubscriptionDetails(this.Sensor.sensorID)
        .subscribe((result) => {
          this.sensorSubscriptionDetails = result;
        });
    }
  }
}
