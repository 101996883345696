<div *ngIf="sensorSubscriptionDetails">
  <div class="sensor-header">
    <i nz-icon nzType="icons:subscription" class="sensor-icon svg-icon"></i>
    <h2>Subscription details</h2>
  </div>
  <div class="additionalInformation">
    <nz-collapse [nzBordered]="false" [nzExpandIconPosition]="'right'">
      <nz-collapse-panel
        #sensorSub
        [nzHeader]="panelheader"
        [nzExtra]="collapseIcon"
        [nzActive]="false"
        nzShowArrow="false"
      >
        <ng-template #panelheader>
          <div class="collapse-header">
            <i nz-icon nzType="icons:info"></i>
            <span class="body-bold">Current subscription</span>
          </div>
        </ng-template>
        <div class="flex-content">
          <div class="parent-flex">
            <div class="subscription-heading">Name</div>
            <div class="subscription-name">
              {{sensorSubscriptionDetails.Subscriptionname}}
            </div>
          </div>
          <div class="parent-flex">
            <div class="subscription-heading">Start date</div>
            <div class="subscription-name">
              {{sensorSubscriptionDetails.Subscriptionstartdate | date :
              DateFormat}}
            </div>
          </div>
          <div class="parent-flex">
            <div class="subscription-heading">End date</div>
            <div class="subscription-name">
              {{sensorSubscriptionDetails.Subscriptionstartdate | date :
              DateFormat}}
            </div>
          </div>
        </div>
        <ng-template #collapseIcon let-active>
          <i nz-icon *ngIf="!sensorSub.nzActive" [nzType]="'icons:plus'"></i>
          <i nz-icon *ngIf="sensorSub.nzActive" [nzType]="'icons:minus'"></i>
        </ng-template>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</div>
