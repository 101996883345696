import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { I18nService } from '../../services/i18n.service';
import { NotificationService, PushPermissionStatus } from '../../services/notification.service';
import { SettingsService } from '../../services/settings.service';

// This reusable component is a standalone component responsible to handle "all notifications" only

@Component({
  selector: 'app-all-notifications-settings',
  templateUrl: './all-notifications-settings.component.html',
  styleUrls: ['./all-notifications-settings.component.scss'],
})
export class AllNotificationsSettingsComponent implements OnInit {
  @Input() public settings: any;
  @Input() public loading: boolean = false;
  @Input() public success: boolean = false;
  @Output() updateSettings = new EventEmitter<any>();

  // Configure all the notifications here (email and push)
  notificationEmailArray:any = [];
  notificationPushArray:any = [];
  showAllNotification: boolean;

  public permission_error: boolean = false;

  public requestingNotificationPermission: boolean = false;
  public notificationPermissionStatus: PushPermissionStatus =
    this.notification.PermissionStatus;
  public pushNotificationModalVisible: boolean = false;
  public pushNotificationModalResolve: (
    value: void | PromiseLike<void>
  ) => void = () => {};
  public pushNotificationModalReject: (reason?: any) => void = () => {};

  public reset: boolean; // used to reset the push notification settings

  public customErrorMessage: string = null;
  public customAlertTitle: string = null;
  public customAlertMessage: string = null;
  public instructionsAddToHomeScreenIOS: boolean = false;

  constructor(
    public router: ActivatedRoute,
    public i18n: I18nService,
    private notification: NotificationService,
    private SettingsService: SettingsService
  ) {}

  ngOnInit() {
    this.router.url.subscribe((url) => {
      this.showAllNotification = url.length === 2 ? true : false;
    });
  }

  get IsSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // source: https://stackoverflow.com/questions/7944460/detect-safari-browser
  }

  get UserEmail(): string {
    return !!this.settings ? this.settings.email : '';
  }

  public getNotificationEmailArray(value:boolean): any {
    return  !!this.settings ? (Object.keys(this.settings)).filter(i=>i.includes('notification') && i.includes('Email')).filter(i=> value==null || this.settings[i]!=value): [];    
  }
  public getNotificationPushArray(value:boolean): any {
    return  !!this.settings ? (Object.keys(this.settings)).filter(i=>i.includes('notification') && i.includes('Push')).filter(i=> value==null || this.settings[i]!=value): [];    
  }

  // Email notification Starts
  get WarningNotificationsEmail(): boolean {
    if (!this.settings) {
      return false;
    } else {
      let notifications = [];
      this.getNotificationEmailArray(null).forEach((val) => {
        if (this.settings[val] !== undefined || null) {
          notifications.push(this.settings[val]);
        }
      });
      return notifications.every((val) => {
        return val === true;
      });
    }
  }
  set WarningNotificationsEmail(value: boolean) {
    if (!this.settings) {
      return;
    } else {
      this.notificationEmailArray = this.getNotificationEmailArray(value);
      this.notificationEmailArray.forEach((val) => {
        if (
          val ===
          this.notificationEmailArray[this.notificationEmailArray.length - 1]
        ) {
          // wait a second before setting the last value
          setTimeout(() => {
            this.settings[val] = value;
          }, 1000);
        } else {
          this.settings[val] = value;
        }
        this.success = true;
      });
    }
  }
  // Email notification Ends

  // Push notification Starts
  get WarningNotificationsPush(): boolean {
    if (!this.settings) {
      return false;
    } else {
      let notifications = [];
      this.getNotificationPushArray(null).forEach((val) => {
        if (this.settings[val] !== undefined || null) {
          notifications.push(this.settings[val]);
        }
      });
      return notifications.every((val) => {
        return val === true;
      });
    }
  }
  set WarningNotificationsPush(value: boolean) {
    if (!this.settings) return;
    const setPushvalue = (value: boolean) => {
      this.notificationPushArray = this.getNotificationPushArray(value);
      this.notificationPushArray.forEach((val) => {
        if (
          val ===
          this.notificationPushArray[this.notificationPushArray.length - 1]
        ) {
          // wait a second before setting the last value
          setTimeout(() => {
            this.settings[val] = value;
          }, 1000);
        } else {
          this.settings[val] = value;
        }
      });
    };

    if (value === true) {
      this.triggerNotificationPermissionPrompt().then(
        () => setPushvalue(value),
        () => {
          this.reset = !this.reset;
        }
      );
    } else {
      setPushvalue(value);
    }
  }
  // Push notification Ends
  public requestNotificationPermission() {
    this.permission_error = false;
    this.requestingNotificationPermission = true;
    this.notification.subscribeToNotifications(
      (status: PushPermissionStatus) => {
        this.requestingNotificationPermission = false;
        if (status === PushPermissionStatus.denied) {
          console.error(
            'Could not subscribe to notifications. Permission denied'
          );
          this.success = false;
          this.permission_error = true;
          this.notificationPermissionStatus = PushPermissionStatus.denied;
          this.closePushNotificationModal(false);
        } else {
          this.notificationPermissionStatus = status;
          this.closePushNotificationModal(true);
        }
      },
      (reason) => {
        console.error('Could not subscribe to notifications', reason);
        this.success = false;
        this.permission_error = true;
        this.requestingNotificationPermission = false;
        this.notificationPermissionStatus = PushPermissionStatus.denied;
        this.closePushNotificationModal(false);
      }
    );
  }

  private triggerNotificationPermissionPrompt(): Promise<void> {
    return new Promise((resolve, reject) => {
      var isResolvedOrRejected = false;
      try {
        if (!('Notification' in window)) {
          if (this.IsSafari) {
            this.customAlert(
              this.i18n.string('notifications_not_supported_title'),
              this.i18n.string('safari_not_supporting_notifications'),
              true
            );
          } else {
            this.customAlert(
              this.i18n.string('notifications_not_supported_title'),
              this.i18n.string('browser_not_supporting_notifications')
            );
          }
          reject();
          isResolvedOrRejected = true;
        } else if (Notification.permission !== 'granted') {
          this.displayNotificationPermissionPrompt(resolve, reject);
          isResolvedOrRejected = true; // just assume it is, once we call this function
        } else {
          resolve();
          isResolvedOrRejected = true;
        }
      } catch (error) {
        this.customError(
          this.i18n.string('error_triggering_notification_prompt') +
            error.message
        );
        reject();
        isResolvedOrRejected = true;
      }
      if (!isResolvedOrRejected) {
        this.customError(
          this.i18n.string('error_triggering_notification_prompt') +
            'unknown reason'
        );
        reject();
      }
    });
  }

  private displayNotificationPermissionPrompt(
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: any) => void
  ) {
    try {
      this.pushNotificationModalResolve = resolve;
      this.pushNotificationModalReject = reject;
      this.pushNotificationModalVisible = true;
    } catch (error) {
      this.customError(
        this.i18n.string('error_displaying_notification_prompt') + error.message
      );
      reject();
    }
  }

  public closePushNotificationModal(resolve: boolean) {
    try {
      if (resolve) {
        this.pushNotificationModalResolve();
      } else {
        this.pushNotificationModalReject();
      }
      this.pushNotificationModalVisible = false;
      this.requestingNotificationPermission = false;
      this.pushNotificationModalResolve = () => {};
      this.pushNotificationModalReject = () => {};
    } catch (error) {
      this.customError(
        this.i18n.string('error_solving_notification_prompt') + error.message
      );
      try {
        this.pushNotificationModalReject();
        this.pushNotificationModalVisible = false;
        this.requestingNotificationPermission = false;
        this.pushNotificationModalResolve = () => {};
        this.pushNotificationModalReject = () => {};
      } catch (error) {
        console.error('critical error', error.message);
      }
    }
  }

  public customError(message: string) {
    this.customErrorMessage = message;
  }

  public customAlert(
    title: string,
    message: string,
    instructionsLink: boolean = false
  ) {
    this.customAlertTitle = title;
    this.customAlertMessage = message;
    this.instructionsAddToHomeScreenIOS = instructionsLink;
  }

  public closeCustomAlert() {
    this.customAlertMessage = null;
    this.instructionsAddToHomeScreenIOS = false;
  }
}
