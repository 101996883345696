import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { start } from 'repl';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, delay, flatMap, map, takeUntil, tap } from 'rxjs/operators';

import { StorageService } from '../../cc-framework/services/storage.service';
import { environment } from '../../environments/environment';
import { Asset, AssetDetail } from '../model/asset';
import { HistoryItem } from '../model/history';
import { Location } from '../model/location';
import { LocationFilter } from '../model/location.filter';
import { AugmentedMockData } from '../model/mock';
import { Country, Notification, OTObject } from '../model/object';
import { MeasurementSystemSettings } from '../model/settings';
import { GenerateFakeTelemetry, TelemetryHistory } from '../model/telemetry_history';

const REFRESH_INTERVAL: number = 1000 * 60 * 55; // 55 minutes

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private loading: Subject<boolean> = new Subject(); // deprecated?

  // data
  private navigation: OTObject[] = [];
  private regions: Location[] = [];
  private notifications: Notification[] = [];

  private asset: AssetDetail = null; // necessary?

  private assetCache = {};
  private CACHE_TIME = 100000;

  private searchTerm: string = '';
  private filter: LocationFilter = new LocationFilter();
  private selectionPreset: Location[] = [];

  // handle for interval refreshing the data (necessary because storage urls are only valid for 1 hour)
  private getRegionsInterval: NodeJS.Timeout = null;

  // observables

  private getNotificationsSubject = new Subject<{
    notifications: Notification[];
    filtered: boolean;
  }>();
  // Deprecated: 2023-02-06, LKlum for Ticket 285776
  // private getNotificationsCountSubject = new Subject<number>();
  private getRegionsSubject = new Subject<{
    regions: Location[];
    loadingComplete: boolean;
    filtered: boolean;
  }>();
  private getPlantsSubject = new Subject<{
    plants: Location[];
    loadingComplete: boolean;
    filtered: boolean;
  }>();
  private getAssetSubject = new Subject<{
    asset: AssetDetail;
    plant: Location;
    breadcrumbsLoading: boolean;
  }>();

  private getNavInProgress = false;

  private filterSubject = new Subject<LocationFilter>();
  private selectionPresetSubject = new Subject<Location[]>(); // selection preset for preselecting location filters

  get Loading(): Subject<boolean> {
    return this.loading;
  }

  get Filtered(): boolean {
    return (
      (!!this.searchTerm && this.searchTerm.length > 0) ||
      this.filter.IsFiltering
    );
  }

  get FilterSettingsCount(): number {
    return this.filter.FilterSettingsCount;
  }

  get debugMode(): boolean {
    return environment.debugMode;
  }

  // helpers for setting filter presets for regions and countries:

  get UnfilteredRegions(): Location[] {
    return this.regions;
  }

  // unfiltered countries
  get UnfilteredAreas(): Location[] {
    return this.regions.map((region) => region.children).flat(2);
  }

  constructor(private storage: StorageService, private http: HttpClient) {}

  subscribeToAsset(
    id: number,
    disableCache = false,
    measurementSystem: MeasurementSystemSettings,
    fn: (result: {
      asset: AssetDetail;
      plant: Location;
      breadcrumbsLoading: boolean;
    }) => void,
    err: (error: any) => void
  ): Subscription {
    const subscription = this.getAssetSubject.subscribe(fn, err);
    this.getAsset(id, disableCache, measurementSystem).subscribe((result) => {
      this.getAssetSubject.next({
        asset: result.asset,
        plant: result.plant,
        breadcrumbsLoading: result.breadcrumbsLoading,
      });

      // this.getRegions().subscribe(() => null);
    });
    return subscription;
  }
  getAsset(
    id: number,
    disableCache = false,
    measurementSystem: MeasurementSystemSettings
  ): Observable<{
    asset: AssetDetail;
    plant: Location;
    breadcrumbsLoading: boolean;
  }> {
    if (
      !disableCache &&
      !!this.assetCache &&
      !!this.assetCache[id] &&
      !!this.assetCache[id].lastRetrieved &&
      +new Date() - +this.assetCache[id].lastRetrieved < this.CACHE_TIME
    ) {
      const asset: AssetDetail = this.assetCache[id];
      const plants = this.regions
        .map((region) => region.children)
        .flat(2)
        .map((countries) => countries.children)
        .flat(2);
      asset.ApplyMeasurementSystem(measurementSystem);
      return of({
        asset: this.assetCache[id],
        plant: plants.find((plant) =>
          plant.Assets.find((item) => item.objectID === id)
        ),
        breadcrumbsLoading: false,
      }).pipe(delay(450));
    } else {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      const unsubscribe = new Subject<void>();
      const outputSubject = new Subject<{
        asset: AssetDetail;
        plant: Location;
        breadcrumbsLoading: boolean;
      }>();
      let breadcrumbsLoading = false;
      this.http
        .get<{ asset: AssetDetail }>(environment.host + 'api/assets/' + id, {
          headers: {
            Authorization: this.storage.Token,
          },
        })
        .subscribe(
          (response: any) => {
            this.asset = AssetDetail.FromServiceResponse(response);
            this.asset.notifications = this.asset.notifications.filter(
              (item, index, array) => {
                if (
                  !item.onlyApplyWorseStatus &&
                  array.filter(
                    (n) =>
                      n.sensorID == item.sensorID &&
                      n.measurementTypeID == item.measurementTypeID &&
                      n.notificationID != item.notificationID &&
                      n.timestamp > item.timestamp
                  ).length > 0
                ) {
                  return false;
                }
                if (item.isCritical) return true;
                const critical = array.find(
                  (_item) =>
                    _item.isCritical &&
                    _item.objectID === item.objectID &&
                    _item.sensorID === item.sensorID &&
                    _item.measurementTypeID === item.measurementTypeID &&
                    _item.timestamp === item.timestamp
                );
                return !critical;
              }
            );
            // get breadcrumbs by loading whole navigation structure
            // TODO: make more performant!
            breadcrumbsLoading = true;
            let once = true;
            this.subscribeToPlants((result: { plants: Location[] }) => {
              if (!once) return;
              once = false;
              if (
                !this.asset.breadcrumbs ||
                this.asset.breadcrumbs.length === 0
              ) {
                const assets: Asset[] = result.plants
                  .map((plant: Location) => plant.Assets)
                  .flat(2);
                const this_asset = assets.find(
                  (asset: Asset) => asset.objectID === this.asset.objectID
                );

                if (!!this_asset) {
                  this.asset.breadcrumbs = this_asset.breadcrumbs;
                  this.asset['picture'] = this_asset['picture'];
                  this.asset['thumbnail'] = this_asset['thumbnail'];
                }
                // store temp object for status override purposes (main logic happens in AssetDetail constructor)
                const temp = new AssetDetail(
                  this.asset,
                  this.asset.breadcrumbs
                );
                // status override:
                if (temp.inactive && !this.asset.inactive) {
                  this.asset.inactive = true;
                  this_asset.inactive = true;
                  this.navigation.find(
                    (asset) => asset.objectID === this.asset.objectID
                  ).inactive = true;
                  this.updateFilter(this.filter);
                } else {
                  if (temp.critical && !this.asset.critical) {
                    this.asset.critical = true;
                    this_asset.critical = true;
                    this.navigation.find(
                      (asset) => asset.objectID === this.asset.objectID
                    ).critical = true;
                    this.updateFilter(this.filter); // trigger update in components
                  } else if (temp.warning && !this.asset.warning) {
                    this.asset.warning = true;
                    this_asset.warning = true;
                    this.navigation.find(
                      (asset) => asset.objectID === this.asset.objectID
                    ).warning = true;
                    this.updateFilter(this.filter); // trigger update in components
                  }
                }
                this.assetCache[id] = Object.assign(temp, this.asset);
                this.assetCache[id].ApplyMeasurementSystem(measurementSystem);
                this.assetCache[id].lastRetrieved = new Date();
                breadcrumbsLoading = false;
                const _plants = this.regions
                  .map((region) => region.children)
                  .flat(2)
                  .map((countries) => countries.children)
                  .flat(2);
                this.getAssetSubject.next({
                  asset: this.asset,
                  plant: _plants.find((plant) =>
                    plant.Assets.find((item) => item.objectID === id)
                  ),
                  breadcrumbsLoading: breadcrumbsLoading,
                });
                outputSubject.next({
                  asset: this.asset,
                  plant: _plants.find((plant) =>
                    plant.Assets.find((item) => item.objectID === id)
                  ),
                  breadcrumbsLoading: breadcrumbsLoading,
                });
                if (!breadcrumbsLoading) unsubscribe.next();
              }
            });
            const plants = this.regions
              .map((region) => region.children)
              .flat(2)
              .map((countries) => countries.children)
              .flat(2);
            this.getAssetSubject.next({
              asset: this.asset,
              plant: plants.find((plant) =>
                plant.Assets.find((item) => item.objectID === id)
              ),
              breadcrumbsLoading: breadcrumbsLoading,
            });
            outputSubject.next({
              asset: this.asset,
              plant: plants.find((plant) =>
                plant.Assets.find((item) => item.objectID === id)
              ),
              breadcrumbsLoading: breadcrumbsLoading,
            });

            if (!breadcrumbsLoading) unsubscribe.next();
          },
          (error) => outputSubject.error(error)
        );
      return outputSubject.pipe(takeUntil(unsubscribe));
    }
  }

  subscribeToNotifications(
    fn: (result: { notifications: Notification[]; filtered: boolean }) => void
  ): Subscription {
    const subscription = this.getNotificationsSubject.subscribe(fn);
    this.getNotifications().subscribe((notifications) => {
      this.getNotificationsSubject.next({
        notifications: this.filterNotifications(notifications),
        filtered: this.Filtered,
      });

      // The following call will update the asset status based on notifications when for example the user has documented a problem as 'fixed'.
      // This should NOT trigger an additional api/nav call if we haven't loaded the navigation hierarchy yet.
      if (this.navigation.length > 0)
        // we already loaded the navigation hierarchy
        this.getRegions().subscribe(() => null);
    });
    return subscription;
  }

  // subscribeToNotificationsCount(fn: (count: number) => void): Subscription {
  //   const subscription = this.getNotificationsCountSubject.subscribe(fn);
  //   this.getNotificationsCount().subscribe((count: number) =>
  //     this.getNotificationsCountSubject.next(count)
  //   );
  //   return subscription;
  // }

  subscribeToFilter(fn: (filter: LocationFilter) => void): Subscription {
    const subscription = this.filterSubject.subscribe(fn);
    this.filterSubject.next(this.filter);
    return subscription;
  }

  subscribeToSelectionPreset(fn: (preset: Location[]) => void): Subscription {
    const subscription = this.selectionPresetSubject.subscribe(fn);
    this.selectionPresetSubject.next(this.selectionPreset);
    return subscription;
  }
  getNotificationsInstantly(): Notification[] {
    return this.notifications;
  }
  getNotifications(): Observable<Notification[]> {
    let notificationResults;
    return this.http
      .get<Notification[]>(environment.host + 'api/notification/', {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(
        tap((result) => (notificationResults = result)),
        flatMap(() =>
          of(this.navigation).pipe(
            map(() =>
              notificationResults.notifications.map(
                (_notification) =>
                  new Notification(_notification, this.navigation)
              )
            ),
            map((notifications: Notification[]) =>
              notifications.filter((item, index, array) => {
                // filter out non-critical notifications, when there are critical notifications present
                if (
                  !item.onlyApplyWorseStatus &&
                  array.filter(
                    (n) =>
                      n.sensorID == item.sensorID &&
                      n.measurementTypeID == item.measurementTypeID &&
                      n.notificationID != item.notificationID &&
                      n.timestamp > item.timestamp
                  ).length > 0
                ) {
                  return false;
                }
                if (item.isCritical) return true;
                const critical = array.find(
                  (_item) =>
                    _item.isCritical &&
                    _item.objectID === item.objectID &&
                    _item.sensorID === item.sensorID &&
                    _item.measurementTypeID === item.measurementTypeID &&
                    _item.timestamp === item.timestamp
                );
                return !critical;
              })
            ),
            tap((notifications: Notification[]) => {
              this.notifications = notifications;
            }),
            tap(() => this.onLoadingFinished())
          )
        ),
        catchError(this.handleError<Notification[]>())
      );
  }

  getAdditionalInfoTypes(): Observable<any> {
    return this.http
      .get<any>(environment.host + 'api/additional_info_types/', {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(catchError(this.handleError<any[]>()));
  }

  getAdditionalInfo(objectID: number): Observable<any[]> {
    return this.http
      .get<any>(environment.host + 'api/additional_info/' + objectID, {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(catchError(this.handleError<any[]>()));
  }

  getObjectHistory(
    objectid: number,
    sensorid: number
  ): Observable<HistoryItem[]> {
    return this.http
      .get<any>(environment.host + 'api/history/' + objectid + '/' + sensorid, {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(
        map((result) =>
          result.items
            .map((item) => Object.assign(new HistoryItem(), item))
            .sort((a: HistoryItem, b: HistoryItem) =>
              b.timestamp.localeCompare(a.timestamp)
            )
        ),
        catchError(this.handleError<HistoryItem[]>())
      );
  }

  // getNotificationsCount(): Observable<number> {
  //   let notificationResults;
  //   return this.http
  //     .get<Notification[]>(environment.host + 'api/notification/', {
  //       headers: {
  //         Authorization: this.storage.Token,
  //       },
  //     })
  //     .pipe(
  //       tap((result) => (notificationResults = result)),
  //       flatMap(() =>
  //         of(this.navigation).pipe(
  //           map(() =>
  //             notificationResults.notifications.map(
  //               (_notification) =>
  //                 new Notification(_notification, this.navigation)
  //             )
  //           ),
  //           map((notifications: Notification[]) =>
  //             notifications.filter((item, index, array) => {
  //               if (
  //                 !item.onlyApplyWorseStatus &&
  //                 array.filter(
  //                   (n) =>
  //                     n.sensorID == item.sensorID &&
  //                     n.measurementTypeID == item.measurementTypeID &&
  //                     n.notificationID != item.notificationID &&
  //                     n.timestamp > item.timestamp
  //                 ).length > 0
  //               ) {
  //                 return false;
  //               }
  //               if (item.isCritical) return true;
  //               const critical = array.find(
  //                 (_item) =>
  //                   _item.isCritical &&
  //                   _item.objectID === item.objectID &&
  //                   _item.sensorID === item.sensorID &&
  //                   _item.measurementTypeID === item.measurementTypeID
  //               );
  //               return !critical;
  //             })
  //           ),
  //           tap(() => this.onLoadingFinished()),
  //           map((notifications: Notification[]) => notifications.length)
  //         )
  //       ),
  //       catchError(this.handleError<number>())
  //     );
  // }

  subscribeToRegions(
    fn: (result: { regions: Location[]; loadingComplete: boolean }) => void
  ): Subscription {
    const subscription = this.getRegionsSubject.subscribe(fn);
    if (!this.getNavInProgress) {
      this.getRegions().subscribe((result) => {});
    }
    return subscription;
  }

  subscribeToPlants(
    fn: (result: { plants: Location[]; loadingComplete: boolean }) => void
  ): Subscription {
    const subscription = this.getPlantsSubject.subscribe(fn);
    if (!this.getNavInProgress) {
      this.getRegions().subscribe((result) => {});
    }
    return subscription;
  }

  public findLocation(ID: number): Location {
    const regions = this.navigation
      .filter((item) => item.layerID === 1) // filter for regions
      .map((item: OTObject) => new Location(item, this.navigation))
      .filter((item) => !!item);
    return regions
      .map((region: Location) => region.findLocation(ID))
      .filter((item) => !!item) // filter old S100 items now that parent object is hidden
      .find((item: Location) => item.objectID === ID);
  }

  public getRegions(
    forceReload: boolean = false,
    noInterval: boolean = false
  ): Observable<Location[]> {
    if (!noInterval) {
      if (this.getRegionsInterval === null) {
        clearInterval(this.getRegionsInterval);
      }
      this.getRegionsInterval = setInterval(() => {
        this.getRegions(true, true).subscribe((result) => {});
      }, REFRESH_INTERVAL);
    }
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    const sortFn = (a: OTObject, b: OTObject) => {
      if (a.isAsset !== b.isAsset) return a.isAsset ? 1 : -1;
      return collator.compare(a.objectName, b.objectName);
    };

    if ((this.navigation.length === 0 || forceReload) && !this.getNavInProgress) {
      this.getNavInProgress = true;
      const unsubscribe = new Subject<void>();
      const outputSubject = new Subject<Location[]>();
      this.onLoadingFinished();
      this.http
        .get<OTObject[]>(environment.host + 'api/nav/', {
          headers: {
            Authorization: this.storage.Token,
          },
        })
        .pipe(
          map(
            // (result) => [...result, ...AugmentedMockData()]
            (result) =>
              this.debugMode ? [...result, ...AugmentedMockData()] : result
          ),
          map((result) => this.removeDuplicateRegions(result)),
          map((result) =>
            this.overrideObjectStatusByNotifications(result, this.notifications)
          ),
          tap((result) => (this.navigation = result.sort(sortFn))),
          tap((result) => (this.getNavInProgress = false)),
          map((result) => result.filter((item) => item.layerID === 1)), // filter for regions
          map((result) => result.sort(sortFn)), // still necessary?
          map((result) =>
            result
              .map((item: OTObject) => new Location(item, this.navigation))
              .filter((item) => !!item)
          ),
          tap((regions) => {
            this.regions = regions;
            this.getRegionsSubject.next({
              regions: regions,
              loadingComplete: true,
              filtered: this.Filtered,
            });
            this.getPlantsSubject.next({
              plants: ApplyFilter(regions, this.filter, this.searchTerm)
                .map((region: Location) => region.children)
                .flat(2)
                .map((country: Location) => country.children)
                .flat(2)
                .filter(
                  (plant: Location) => plant.NumSensors > 0 || plant.filtered
                ), // only apply this filter to non-filtered plants
              // (otherwise the filter acts weird to the user because it ignores grayed out assets)
              loadingComplete: true,
              filtered: this.Filtered,
            });
            this.getNotificationsSubject.next({
              notifications: this.filterNotifications(this.notifications),
              filtered: this.Filtered,
            });
          }),
          catchError(this.handleError<Location[]>('getNav'))
        )
        .subscribe((items: Location[]) => {
          outputSubject.next(items);
          unsubscribe.next();
        });
      return outputSubject.pipe(takeUntil(unsubscribe));
    } else {
      return of(this.navigation).pipe(
        map((result) => this.removeDuplicateRegions(result)),
        map((result) =>
          this.overrideObjectStatusByNotifications(result, this.notifications)
        ),
        map((result) => result.filter((item) => item.layerID === 1)), // filter for regions
        map((result) => result.sort(sortFn)),
        map((result) => {
          return result
            .map((item: OTObject) => new Location(item, this.navigation))
            .filter((item) => !!item);
        }),
        tap((regions) => {
          this.regions = regions;
          this.getRegionsSubject.next({
            regions: regions,
            loadingComplete: true,
            filtered: this.Filtered,
          });
        }),
        tap((regions) =>
          this.getPlantsSubject.next({
            plants: ApplyFilter(regions, this.filter, this.searchTerm)
              .map((region: Location) => region.children)
              .flat(2)
              .map((country: Location) => country.children)
              .flat(2)
              .filter(
                (plant: Location) => plant.NumSensors > 0 || plant.filtered
              ), // only apply this filter to non-filtered plants
            // (otherwise the filter acts weird to the user because it ignores grayed out assets)
            loadingComplete: true,
            filtered: this.Filtered,
          })
        ),
        tap((regions) =>
          this.getNotificationsSubject.next({
            notifications: this.filterNotifications(this.notifications),
            filtered: this.Filtered,
          })
        )
      );
    }
  }

  public updateFilter(filter: LocationFilter) {
    this.filter = filter;
    this.filter.preselection = this.selectionPreset.length;
    setTimeout(() => {
      this.getRegions().subscribe((result) => {});
      this.getNotificationsSubject.next({
        notifications: this.filterNotifications(this.notifications),
        filtered: this.Filtered,
      });
      this.filterSubject.next(this.filter);
    }, 1);
  }

  public updateSelectionPreset(preset: Location[]) {
    if (
      this.selectionPreset
        .map((item) => item.objectID)
        .reduce((a, b) => a + ';' + b, '') ===
      preset.map((item) => item.objectID).reduce((a, b) => a + ';' + b, '')
    )
      return;
    this.selectionPreset = preset;
    this.filter.preselection = this.selectionPreset.length;
    this.selectionPresetSubject.next(this.selectionPreset);
    this.filterSubject.next(this.filter);
  }

  public resetLocationFilter() {
    this.filter.location = [];
    this.updateFilter(this.filter);
  }

  public clearFilter() {
    this.updateFilter(new LocationFilter());
  }

  public getFilter() {
    return this.filter;
  }

  public updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
    setTimeout(() => {
      this.getRegions().subscribe((result) => {});

      this.getNotificationsSubject.next({
        notifications: this.filterNotifications(this.notifications),
        filtered: this.Filtered,
      });
    }, 1);
  }

  public getSearchTerm(): string {
    return this.searchTerm;
  }

  public getNanoPreciseAssetsToken(): Observable<any[]> {
    return this.http
      .get<any>(environment.host + 'api/np/login/', {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(catchError(this.handleError<any[]>()));
  }

  public getNanoPreciseRefreshToken(): Observable<any[]> {
    return this.http
      .get<any>(environment.host + 'api/sso_login/', {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(catchError(this.handleError<any[]>()));
  }

  public goToNanoPreciseDashboard(assetId: number): Observable<any> {
    return this.http
      .get<any>(environment.host + 'api/np_sso_params/' + assetId, {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(tap(result => console.log(result)),  catchError(this.handleError<any>()));
  }

  public getNanoPreciseAssetsHealthStatus(
    assetId: number
  ): Observable<any[]> {
    return this.http
      .get<any[]>(environment.host + 'api/np_health_status/' + assetId, {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(catchError(this.handleError<any[]>()));
  }

  getCountryList(): Observable<Country[]> {
    return this.http.get<Country[]>(
      environment.host + 'api/registration_country_list/'
    );
  }

  public getSensorSubscriptionDetails( sensorId: number): Observable<any[]> {
    return this.http
      .get<any>(environment.host + 'api/subscription/' + sensorId, {
        headers: {
          Authorization: this.storage.Token,
        },
      })
      .pipe(catchError(this.handleError<any[]>()));
  }

  getTelemetry(
    objectID: number,
    sensorID: number,
    measurementID: number,
    measurementSystem: MeasurementSystemSettings,
    timeframe: {
      start: Date;
      end: Date;
      custom: boolean; // probably useless here, but part of original timeframe object
    }
  ): Observable<TelemetryHistory> {
    if (!timeframe) {
      timeframe = {
        //  start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        start: new Date(new Date().setDate(new Date().getDate() - 1)),
        end: new Date(),
        custom: false,
      };
    }
    // push the "to date" a day and an hour further into the future to deal with filtering issues
    const end = new Date(
      new Date(timeframe.end).setHours(new Date(timeframe.end).getHours() + 25)
    );
    return this.http
      .post<TelemetryHistory>(
        environment.host +
          'api/telemetry/' +
          objectID +
          '/' +
          sensorID +
          '/' +
          measurementID,
        {
          from: new Date(timeframe.start).toISOString(),
          to: end.toISOString(),
        },
        {
          headers: {
            Authorization: this.storage.Token,
          },
        }
      )
      .pipe(
        map((response: any) => {
          const telemetry = new TelemetryHistory();
          telemetry.metadata = response.metadata.map((metadata) => {
            metadata.timeframe = timeframe;
            return metadata;
          });
          telemetry.telemetry = environment.fakeTelemetry
            ? telemetry.metadata
                .map((metadata) =>
                  GenerateFakeTelemetry(
                    metadata.measurementTypeID,
                    metadata.unit
                  )
                )
                .flat(2)
            : response.telemetry;
          telemetry.thresholds = response.thresholds;
          telemetry.ApplyMeasurementSystem(measurementSystem);
          return telemetry;
        })
      );
  }

  onLoadingStarted() {
    this.loading.next(true);
  }

  onLoadingFinished() {
    this.loading.next(false);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      console.log(`${operation} failed: ${error.message}`);

      if (operation === 'getNav') this.getNavInProgress = false;

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private filterNotifications(notifications: Notification[]): Notification[] {
    // determine assets which would pass the current filtering options
    const assets: Asset[] = this.navigation
      .filter((item) => item.layerID === 1) // filter for regions
      .map((item: OTObject) => new Location(item, this.navigation))
      .map((item: Location) => item.ApplySearch(this.searchTerm))
      .filter((item) => !!item)
      .map((item: Location) => item.ApplyFilter(this.filter))
      .filter((item) => !!item)
      .map((item: Location) => item.Assets)
      .flat(2);

    // return only those notifications which belong to the previously filtered assets
    return notifications
      .map((notification: Notification) => {
        const asset = assets.find(
          (_asset: Asset) => _asset.objectID === notification.objectID
        );
        if (!asset) return null;
        notification.breadcrumbs = asset.breadcrumbs;
        return notification;
      })
      .filter((item) => !!item)
      .sort((a, b) =>
        a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0
      );
  }

  private overrideObjectStatusByNotifications(
    objects: OTObject[],
    notifications: Notification[]
  ): OTObject[] {
    return objects.map((item: OTObject) => {
      if (!item.inactive) {
        let notis = notifications.find(
          (_item) => _item.objectID === item.objectID && _item.inactive
        );
        if (!!notis) {
          item.critical = false;
          item.warning = false;
          item.inactive = true;
        } else if (!item.critical) {
          let notis = notifications.find(
            (_item) =>
              _item.objectID === item.objectID &&
              _item.isCritical &&
              !_item.inactive
          );
          if (!!notis) {
            item.critical = true;
            item.warning = false;
            item.inactive = false;
          } else {
            notis = notifications.find(
              (_item) =>
                _item.objectID === item.objectID &&
                _item.isWarning &&
                !_item.inactive
            );
            if (!!notis) {
              item.warning = true;
            }
          }
        }
      }

      return item;
    });
  }

  private removeDuplicateRegions(objects: OTObject[]): OTObject[] {
    function findDuplicate(
      item: OTObject,
      index: number,
      array: OTObject[]
    ): number {
      // only check for duplicates above plant level
      // why? we only want to remove duplicate Regions such as "Europe" and "Germany" which are different objects in the database
      // depending on customer
      if (item.curLayer !== 'a' && item.curLayer !== 'b') return -1;
      const temp = array.findIndex(
        (_item) => _item.objectName === item.objectName
      );
      if (index !== temp) return array[temp].objectID;
      else return -1;
    }
    const idMapping = {};
    const output = objects.filter((item, index, array) => {
      const duplicate = findDuplicate(item, index, array);
      if (duplicate >= 0) {
        idMapping[item.objectID] = duplicate;
        return false;
      } else {
        return true;
      }
    });

    // before returning, fix all parent-child-relationships using idMapping
    return output.map((item) => {
      item.parentID = !!idMapping[item.parentID]
        ? idMapping[item.parentID]
        : item.parentID;
      return item;
    });
  }

  clearCache() {
    this.navigation = [];
    this.notifications = [];
    this.asset = null;
    this.assetCache = {};
    this.searchTerm = '';
    this.filter = new LocationFilter();
    this.selectionPreset = [];
  }
}

function ApplyFilter(
  list: Location[],
  filter: LocationFilter,
  searchTerm: string
) {
  // pre filtering countryOrRegionSelected
  if (!!filter.countryOrRegionSelected) {
    const region_match = list.find(
      (item) => item.objectID === filter.countryOrRegionSelected
    );

    if (!!region_match) {
      filter.location = [
        region_match.objectID,
        // ...filter.location.slice(1)
      ];
      // filter.preselection = Math.max(1, filter.preselection);
    } else {
      const countries = list.map((item) => item.children).flat(2);
      const country_match = countries.find(
        (item) => item.objectID === filter.countryOrRegionSelected
      );
      if (!!country_match) {
        const region = list.find(
          (item) =>
            !!item.children.find(
              (_item) => _item.objectID === country_match.objectID
            )
        );
        if (!!region) {
          filter.location = [
            region.objectID,
            country_match.objectID,
            // ...filter.location.slice(2),
          ];
          // filter.preselection = Math.max(2, filter.preselection);
        }
      }
    }
  }

  return list
    .map((item) => item.ApplySearch(searchTerm))
    .filter((item) => !!item)
    .map((item) => item.ApplyFilter(filter))
    .filter((item) => !!item);
}
